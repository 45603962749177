import bootstrap from 'bootstrap.native/dist/bootstrap-native';

const $itemCodeSearch = document.getElementById('itemcode-search');
const $itemCodeSearchBtn = document.getElementById('itemcode-search-btn');

const searchForItemCode = () => {
    if ($itemCodeSearch.value.length > 0) {
        window.location.href = `/picture-frame-mouldings/detail/Moulding/${$itemCodeSearch.value}`;
    }
}

$itemCodeSearchBtn.addEventListener('click', searchForItemCode);
$itemCodeSearch.addEventListener('keyup', (e) => {
    if (e.key === 'Enter') {
        searchForItemCode();
    }
});

var portraits = document.querySelectorAll('#team .col');

// attach a tooltip for each
Array.from(portraits).forEach(
  tip => new bootstrap.Tooltip( tip, {
    title: tip.querySelector('img').getAttribute('alt'),
    placement: 'top', // string
    animation: true, // boolean
    delay: 150, // integer
  })
)